<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <p v-show="!propOpenedInModal">
              {{cvCardSubHeader}}
            </p>
            <form action="#" v-if="vmPostFormData && Object.keys(vmPostFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label
                    for="validationpost_title">
                    {{cvPostTitleLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_title"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_desc">
                    {{cvPostDescLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_desc"
                    type="text"
                    class="form-control"
                    required/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_image">
                    {{cvPostImageLabel}}</label>
                  <b-form-file
                    type="text"
                    class="form-control"
                    required
                    @change="onChange"
                    ref="post_image"/>
                </div><div class="col-md-12 mb-3">
                  <label
                    for="validationpost_type">
                    {{cvPostTypeLabel}}</label>
                  <input
                    v-model="vmPostFormData.post_type"
                    type="text"
                    min="1"
                    max="4"
                    class="form-control"
                    required/>
                </div>
              </div>
            <div class="form-group row spinner_add">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary float-right" :disabled="cvLoadingStatus">
                  <b-spinner label="Spinning">
                  </b-spinner>
                </button>
                <button v-else type="button" class="btn btn-primary" @click="postEdit()">
                  {{cvSubmitBtn}}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { Posts } from "../../../FackApi/api/post"
import ApiResponse from "../../../Utils/apiResponse.js"

export default {
  name: "PostEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propPostObj: {
      type: Object,
      default: function () {
        return {
          "post_title": "",
          "post_desc": "",
          "post_image": "",
          "post_type": "",
          "post_url": ""
        }
      }
    }
  },
  data () {
    return {
      cvCardTitle: "Edit post",
      cvCardSubHeader: "Edit post ",
      cvSubmitBtn: "Edit",
      cvPostTitleLabel: "Post Title",
      cvPostDescLabel: "Post Desc",
      cvPostImageLabel: "Post Image",
      cvPostTypeLabel: "Post Type",
      cvPostUrlLabel: "Post Url",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Post",
      vmPostFormData: {},
      cvLoadingStatus: false
    }
  },
  mounted () {
    this.postView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmPostFormData) {
          if (!this.vmPostFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * postView
     */
    async postView () {
      try {
        if (this.propOpenedInModal) {
          this.vmPostFormData = this.propPostObj
        }
        else {
          let postId = this.$route.params.post_id
          let postViewResp = await Posts.postView(this, postId)
          if (postViewResp && postViewResp.resp_status) {
            this.vmPostFormData = postViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at postView() and Exception:", err.message)
      }
    },
    /**
     * postEdit
     */
    async postEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let postAddResp = await Posts.postEdit(this, this.vmPostFormData)
        await ApiResponse.responseMessageDisplay(this, postAddResp)
        if (postAddResp && !postAddResp.resp_status) {
          return false
        }
        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosePostEditModal", this.vmPostFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at postEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * setDefaultValue
     */
    async setDefaultValue () {
      try {
        this.$refs.post_image.value = this.vmPostFormData.post_image
      }
      catch (err) {
        console.error("Exception occurred at setDefaultValueMultiselect() and exception", err.message)
      }
    },
    /**
     * Onchange
     */
    onChange (e) {
      try {
        let input = e.target
        if (input.files.length > 0 && input.files[0]) {
          this.vmPostFormData.post_image = input.files[0]
          // const reader = new FileReader()
          // reader.onload = (e) => {
          //   this.vmPostFormData.post_image = reader.result
          // }
          // reader.readAsDataURL(input.files[0])
        }
      }
      catch (err) {
        console.error("Exception occurred at onChange() and Exception:", err.message)
      }
    }
  }
}
</script>
